import React from 'react'
// import Navbar from '../component/Navbar/Navbar'
// import Navbar2 from '../component/Navbar2'
import Footer from '../component/Footer/Footer'
import Naavbar from '../component/Navbar/Naavbar'

function Academy() {
  return (
    <div>
        <Naavbar/>
        {/* <Navbar2/> */}
    
{/* banner  */}
<nav className="word-re-aca-au">
    <h1><b>ACADEMY</b></h1>
  </nav>  

{/* <end header */}

<main>
  <br/>
  <br/>
  <br/>
  <br/>
 <br/>
 <br/>
 <h3 style={{color:'#21005d'}}><b className='flex justify-center text-lg'>  COMING SOON</b></h3>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
</main>
{/* <end main>
<footer> */}
<Footer/>
</div>
  )
}

export default Academy
