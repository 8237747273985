import React from 'react'
// import Navbar from '../component/Navbar/Navbar'
// import Navbar2 from '../component/Navbar2'
import Naavbar from '../component/Navbar/Naavbar'
import Footer from '../component/Footer/Footer'

function Blog() {
  return (
    <div>
      <Naavbar/>
           {/* <Navbar2/> */}

<nav className="word-coc-blog">
    <h1><b>BLOG</b></h1>
  </nav>  

  <main>
  <br/>
  <br/>
  <br/>
  <br/>
 <br/>
 <br/>
 <h3 style={{color:'#21005d'}}><b className='flex justify-center text-lg'>  COMING SOON</b></h3>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
</main>

<Footer/>
</div>

  )
}

export default Blog